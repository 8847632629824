import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Code from './screens/Code';
import Home from './screens/Home';
import Credits from './screens/Credits';
import Conditions from './screens/Conditions';
import PrivacyPolicy from './screens/PrivacyPolicy';
import Dice from './screens/Dice';
import DiceLink from './screens/DiceLink';
import Go from './screens/Go';
import Gift from './screens/Gift';
import MarketPlaceEvent from './screens/MarketPlaceEvent';
import Unsubscribe from './screens/Unsubscribe';
import EarlyBird from './screens/EarlyBird';
import DownloadEarlyBird from './screens/DownloadEarlyBird';
import CompensationComponent from './screens/CompensationComponent';

function App() {
  return (
    <>
      <Helmet>
        <script src="https://js.sentry-cdn.com/3e8eb2e7fb284233b5c3763b05f08e29.min.js" crossorigin="anonymous"></script>
      </Helmet>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/credits" component={Credits} />
          <Route exact path="/conditions" component={Conditions} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/t/:id" component={Code} />
          <Route exact path="/dice-link" component={DiceLink} />
          <Route exact path="/dice/exchange" component={DiceLink} />
          <Route exact path="/dice" component={Dice} />
          <Route exact path="/go" component={Go} />
          <Route exact path="/marketplaceEvent/:id" component={MarketPlaceEvent} />
          <Route exact path="/gft/:id" component={Gift} />
          <Route exact path="/unsubscribe/:token?" component={Unsubscribe} />
          <Route exact path="/early-bird" component={EarlyBird} />
          <Route exact path="/primavera-pro" component={DownloadEarlyBird} />
          <Route exact path="/madrid-2023-compensation" component={CompensationComponent} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
